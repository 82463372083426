<template>
    <div class="appoincontent">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div class="dei_rowp">
            <el-button type="primary" size="small" class="but" @click="handExamine">
                <i class="el-icon-plus"></i>
                {{$t('devtable.sh')}}
            </el-button>
            <el-button type="primary" size="small" class="but" @click="handExaminetongbu">
                <i class="el-icon-plus"></i>
                {{$t('devtable.tb')}}
            </el-button>
            <div @click="handFromshow"><el-input
                size="small"
                class="dev_dowm"
                placeholder=""
                suffix-icon="el-icon-arrow-down"
                :readonly="true"
                >
            </el-input></div>
            </div>
        </div>

        <div class="appoin_row" v-show="searflag">
          <el-form :inline="true" size="small" :model="searchParams" class="demo-form-inline">
            <el-form-item :label="$t('devtable.xm')">
              <el-input v-model="searchParams.name" :placeholder="$t('devtable.xm')"></el-input>
            </el-form-item>

            <el-form-item :label="$t('devtable.sjhmN')">
              <el-input v-model="searchParams.mobile" :placeholder="$t('devtable.sjhmN')"></el-input>
            </el-form-item>
            
            <el-form-item>
              <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <Tables @handlpages="handlpages" @handleSizeChange="handleSizeChange" @handles="handles" @handlerow="handlerow" @handleSelectionChange="handleSelectionChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" 
        :configs="configs" >
        </Tables>
        <!-- <Dialog :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
          <div class="dialogimg">
             <el-image :src="preview_imgurl"></el-image>
          </div>          
        </Dialog> -->
        <Dialog ref="dialog" :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
            <div style="display:flex; justify-content: center;max-height:250px">
            <el-image :src="preview_imgurl"></el-image>
            </div>
        </Dialog>  
    </div>
</template>
<script>
import Tables from '@/components/table'
import Dialog from '@/components/dialog/img.vue'
import {formatDate} from '../../utils/date'
import methodlist from '../../utils/methods'

export default {
    components:{
        Tables,
        Dialog
    },
    data(){
        return{
            searflag:false,
            searchParams: {
                name: '',
                mobile: '',
                start_time: 0,
                end_time: 0,
                reg_face_status: '-1',                
            },
            dialog:{
              dialogVisible:false,
              title:this.$t('devtable.yllfryzp')
            },
            
            tableData: [
            
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            operation: {
                width:'120px',
                data:[
                  {
                    name: this.$t('tablename.sc'),
                    type: 'danger'
                  },
                ]
            },
            preview_imgurl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg',
            visitorSelList: [],
            UserInfo:'',
            tongbuid:[]
        }
    },
    computed:{
        tableLabel:function(){
            return [
                 {
                    prop: 'name',
                    label: this.$t('devtable.lfrxm'),
                    align: 'center',
					width:105
                },
                {
                    prop: 'mobile',
                    label: this.$t('devtable.sjhm'),
                    align: 'center'
                },
                {
                    prop: 'gender_hint',
                    label: this.$t('devtable.xb'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bfrbmT'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'person_name',
                    label: this.$t('devtable.bfrr'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'visitor_type',
                    label: this.$t('devtable.rylxS'),
                    align: 'center',
					width:105
                },
                {
                    prop: 'face_url_hint',
                    label: this.$t('devtable.lfrzpD'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'visit_time_hint',
                    label: this.$t('devtable.lfsj'),
                    align: 'center',
                    width:170
                },
                {
                    prop: 'car_number',
                    label: this.$t('devtable.lfcph'),
                    align: 'center',
					width:124
                },
                {
                    prop: 'approve_state_hint',
                    label: this.$t('devtable.shzt'),
                    align: 'center',
                     width:120
                },
                {
                    prop: 'is_syn',
                    label: this.$t('devtable.tbzt'),
                    align: 'center',
                    width:176
                },
                {
                    prop: 'syn_time',
                    label: this.$t('devtable.tbsj'),
                    align: 'center',
                    width:'165'
                },
                
            ]
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getApproveVisitorList(1);
    },
    methods:{
        //预览人脸图片
        handles(v){
            console.log("handles",v);
            this.$refs.dialog.dialogVisible = true
            if(v.face_url.length > 0)
            this.preview_imgurl = methodlist.resident_pic_base + v.face_url +"?tempid="+Math.random()        

        },        
        handlpages(v){
            this.configs.page_index=v
            this.getApproveVisitorList();
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getApproveVisitorList();
        },
        handlerow(){
            this.userdialog.dialogVisible=true            
        },
        //  x 关闭弹窗
        handleClose(){            
            this.$refs.dialog.dialogVisible = false
        },
        // 预览关闭弹窗
        dialogVisibletrue(){
            
            this.$refs.dialog.dialogVisible = false
        },
        //审核访客
        handExamine(){
            console.log("审核");
            
            if(this.visitorSelList.length > 0){
                var ids = '';
                this.visitorSelList.forEach(item=>{
                    ids += ids.length == 0 ? item.id : (','+ item.id);
                })
                const params = {
                    method: methodlist.ee_visitor_batch_approve,
                    id: ids,
                    agent_id: this.UserInfo.agent_id,
                    manager_id: this.UserInfo.manager_id, 
                }
                //需要添加同步
                this.$serve(params).then(res => {
                    console.log(res);
                    if(res.data.code == 0){
                            this.$message({
                            type: 'success',
                            message: this.$t('devtable.shcg')
                            })
                    }else{
                        if(res.data.code == -13){
                            this.$message({
                            type: 'warning',
                            message: this.$t('devtable.zcrlsbbzx')
                            })
                        }else{
                            this.$message({
                            type: 'warning',
                            message:this.$t('devtable.shsb')
                            })
                        }
                    }
                });
            }else{
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxzxyshdfk')
                })                
            }
        },
        // 同步
        handExaminetongbu(){
            let data={
                    method:'co.person.key.syn',
                    id:this.tongbuid.toString(),
                    agent_id:this.UserInfo.agent_id,
                    manager_id:this.UserInfo.manager_id
                }
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                         this.configs.loading= false
                        this.$message({
                            message: this.$t('devtable.tbcg'),
                            type: 'success'
                        });
                        this.getApproveVisitorList()
                    }else{
                        this.configs.loading= false
                        this.$message({
                            message: this.$t('devtable.tbsb'),
                            type: 'error'
                        });
                    }
                })
        },
        handFromshow(){
            this.searflag = !this.searflag
        },
        onSubmit() {
            console.log('submit!');
            this.getApproveVisitorList(1);
        },
        handleSelectionChange(v){
            
            this.visitorSelList = v;
            v.forEach(element => {
                if(this.tongbuid.indexOf(element.id)==-1){
                    this.tongbuid.push(element.id)
                } 
            });
            console.log(this.tongbuid);
        },
              //获取访客列表
        getApproveVisitorList(pageIndex){
            const params = {
                method: methodlist.ee_visitor_approve_list,
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id, 
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,                
                name: this.searchParams.name,
                mobile: this.searchParams.mobile,
                start_time: this.searchParams.start_time,
                end_time: this.searchParams.start_time,
                reg_face_status: this.searchParams.reg_face_status,
                
            }

            this.$serve(params).then(res => {
            console.log(res);
            this.tableData = res.data.data;
            this.tableData.forEach(el=>{
                if(el.gender == 0){
                    el.gender_hint={
                    name: this.$t('devtable.male'),
                    type: 'primary',
                    state: true
                    }
                }else{
                    el.gender_hint={
                    name: this.$t('devtable.female'),
                    type: 'primary',
                    state: true
                    }
                }
                el.visitor_type = {
                    name: this.$t('devtable.fk'),
                    state: true
                }
                if(el.is_syn==0){
                    el.is_syn={
                        name :this.$t('devtable.wtb'),
                        state: true,
                        type: 'warning'
                    }
                }else if(el.is_syn==1){
                    el.is_syn={
                        name :this.$t('devtable.rlwtb'),
                        state: true,
                        type: 'warning'
                    }
                }else if(el.is_syn==2){
                    el.is_syn={
                        name :this.$t('devtable.kwtb'),
                        state: true,
                        type: 'warning'
                    }
                }else{
                    el.is_syn={
                        name :this.$t('devtable.ytb'),
                        state: true,
                        type: 'success'
                    }
                }
                if(el.syn_time == 0){
                    el.syn_time = ''
                }else{
                    el.syn_time = {
                        name: ''+formatDate(new Date(el.syn_time * 1000), 'yyyy-MM-dd hh:mm'),
                        type: 'primary',
                        state: true
                    }
                }  
             

                if(el.approve_state == 0){
                    el.approve_state_hint ={
                        name: this.$t('devtable.wsp'),
                        state: true,
                        type: 'warning'
                    }
                }else{
                    el.approve_state_hint ={
                        name: this.$t('devtable.ysp'),
                        state: true,
                        type: 'success'
                    }
                }

                el.face_url_hint = {
                    name:this.$t('devtable.yl'),
                    but:true,                                        
                    type:'success'
                }

                if(el.visit_time == 0){
                    el.visit_time_hint = ''
                }else{
                    el.visit_time_hint = {
                        name: ''+formatDate(new Date(el.visit_time * 1000), 'yyyy-MM-dd hh:mm'),
                        type: 'primary',
                        state: true
                    }
                }                
            })
            this.configs.total = res.data.count;
            })
        }
    }
}
</script>
<style lang="less" scoped>
.appoincontent{
    background-color: #fff;
    padding: 20px;
    .dei_rowp{
      display: flex;
      width: 30%;
      .dev_dowm{
        width: 200px;
      }
      .but{
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    .appoin_row{
        margin-top: 20px;
        padding: 20px;
    }
}
</style>